import cx from 'classnames'
import { FieldProps } from 'informed'
import * as React from 'react'

import Checkbox from './CheckBox'
import styles from './CheckBox.module.scss'
import Input from '../Input'
import { Label } from '../Label'

type Props = FieldProps<boolean, unknown> &
    Pick<React.ComponentProps<typeof Checkbox>, 'variant'>

const InformedCheckButton = ({
    disabled,
    children,
    className,
    variant = 'standard',
    ...fieldProps
}: Props) => (
    <Label className={cx(styles.label, className)} disabled={disabled}>
        <Input<boolean>
            {...fieldProps}
            disabled={disabled}
            className={styles.informedInput}
            inputClassName={styles.input}
            type="checkbox"
            notify={undefined}
        >
            <span
                className={cx(styles.checkbox, {
                    [styles.emphasized]: variant === 'emphasized',
                })}
            />
        </Input>
        {children}
    </Label>
)

export default InformedCheckButton
