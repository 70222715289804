import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import * as React from 'react'

import Text from '../typography/Text'

const StyledText = styled(Text)`
    float: right;
    padding-left: 1em;
`

const Optional = () => (
    <StyledText color="grey" variant="secondary">
        <Trans id="core.input.optional">optional</Trans>
    </StyledText>
)

export default Optional
