import { Logo as DefaultLogo, Props } from 'components/src/core/Logo/Logo'
import * as React from 'react'

import LogoSmall from './logo-small.svg?react'
import LogoStandard from './logo.svg?react'

const Logo = (props: Props) => (
    <DefaultLogo {...props} logo={LogoStandard} smallLogo={LogoSmall} />
)

export default Logo
