import { i18n } from '@lingui/core'
import { t, Trans } from '@lingui/macro'

import Link from './navigation/Link'

const TermsLink = () => (
    <Link
        name="terms and conditions"
        category="account.register"
        to={t({
            id: 'core.termsAndConditionsLink',
            message: `/terms-conditions`,
        })}
        target="_blank"
        variant="inverted"
    >
        <Trans id="account.register.termsLinkLabel">Terms & Conditions</Trans>
    </Link>
)

export default TermsLink
