import { css } from '@emotion/react'
import { MessageDescriptor, i18n } from '@lingui/core'
import { defineMessage } from '@lingui/macro'
import * as React from 'react'

import Price from '../../presentation/Price'
import { QuoteDataTotalSegmentInterface } from '../../types'
import overridable from '../../utils/overridable'

export const priceStyling = css`
    font-size: inherit;
    font-weight: inherit;
    white-space: nowrap;
    text-align: right;
    display: block;
`

export const SegmentCodes = {
    SUBTOTAL: 'subtotal',
    SHIPPING: 'shipping',
    TAX: 'tax',
    GRAND_TOTAL: 'grand_total',
    DISCOUNT: 'discount',
    FREE_GIFTS: 'free_gifts',
}

export const segmentCodeTitles: { [key: string]: MessageDescriptor } = {
    [SegmentCodes.SHIPPING]: defineMessage({
        id: 'cart.cartTotals.shipping',
        message: `Delivery`,
    }),
    [SegmentCodes.TAX]: defineMessage({
        id: 'cart.cartTotals.tax',
        message: `Tax`,
    }),
    [SegmentCodes.GRAND_TOTAL]: defineMessage({
        id: 'cart.cartTotals.grandTotal',
        message: `Total (incl VAT)`,
    }),
}

// The original type is wrong as value is nullable
type FixedQuoteDataTotalSegmentInterface = QuoteDataTotalSegmentInterface & {
    value?: number
}

export interface Props {
    segment: FixedQuoteDataTotalSegmentInterface
    title: React.ReactNode | undefined
    value: React.ReactNode | undefined
}

export const CartTotalsFooterPrice = ({ segment, title, value }: Props) => (
    <tr>
        <td>{title || i18n._(segmentCodeTitles[segment.code])}</td>
        <td
            // eslint-disable-next-line react/no-unknown-property
            css={priceStyling}
        >
            {value || <Price price={segment.value || 0} css={priceStyling} />}
        </td>
    </tr>
)

export default overridable('CartTotalsFooterPrice')(CartTotalsFooterPrice)
