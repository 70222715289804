import { i18n } from '@lingui/core'
import { t, Trans } from '@lingui/macro'

import Link from './navigation/Link'

const PrivacyPolicyLink = () => (
    <Link
        name="privacy"
        category="account.register"
        to={t({
            id: 'core.privacyLink',
            message: `/privacy`,
        })}
        target="_blank"
        variant="inverted"
    >
        <Trans id="account.register.privacyLinkLabel">Privacy Policy</Trans>
    </Link>
)

export default PrivacyPolicyLink
