import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { t, Trans } from '@lingui/macro'
import { Form } from 'informed'
import { useState } from 'react'

import { useActiveStoreView } from '@emico/storeviews'

import { useIsEmailAvailable } from './account/IsEmailAvailable'
import styles from './AccountAccessForm.module.scss'
import BackIcon from './core/BackIcon'
import Button from './input/Button'
import Checkbox from './input/CheckBox/Informed'
import Date from './input/Date'
import { Email } from './input/Email'
import FormError from './input/FormError'
import FormGroup from './input/FormGroup'
import Input from './input/Input'
import { Label } from './input/Label'
import Optional from './input/Optional'
import { Password } from './input/Password'
import Submit from './input/Submit'
import FeedbackMessage from './input/SuccessMessage'
import Icon from './media/Icon'
import Link from './navigation/Link'
import { useNewsletterSubscribe } from './newsletter/NewsletterEmailSubscribe.query'
import paths from './paths'
import PrivacyPolicyLink from './PrivacyPolicyLink'
import RewardsLink from './RewardsLink'
import SubscribeInput from './SubscribeInput'
import TermsLink from './TermsLink'
import Heading from './typography/Heading'
import Text from './typography/Text'
import { LoginErrors as ogLoginErrors, useLoginForm } from './useLoginForm'
import validateRequired from './utils/validateRequired'

const StyledBackIcon = styled(BackIcon)`
    margin-right: 1em;
`

type FormType = 'login' | 'register'

interface AccountLoginPageFormValues {
    username: string
    password?: string
    firstname?: string
    lastname?: string
    dob?: string
    newsletter?: boolean
    gdprChecked: boolean
}

const Intro = styled(Text)`
    margin-bottom: 2em;
`

const StyledCheckBox = styled(Checkbox)`
    & input {
        width: 0;
    }
`

/**
 * @deprecated Login using useLoginForm. These errors might change in the future.
 */
export const LoginErrors = ogLoginErrors

export const LoginError = ({
    error,
    autoHide,
}: {
    error: string | undefined
    autoHide?: boolean
}) => {
    let errorMessage

    if (error) {
        switch (error) {
            case LoginErrors.invalidCredentials:
            case LoginErrors.invalidCredentialsDE:
            case LoginErrors.invalidCredentialsNL:
                errorMessage = (
                    <Trans id="user.loginPage.invalidCredentialsError">
                        The provided credentials were incorrect. Try again or
                        use password recovery to get a new password.
                    </Trans>
                )
                break
            case LoginErrors.unknownAccount:
            case LoginErrors.unknownAccountDE:
            case LoginErrors.unknownAccountNL:
                errorMessage = (
                    <Trans id="user.loginPage.unknownAccountError">
                        The combination of the e-mail address and the password
                        is not known to us.
                    </Trans>
                )
                break
            case LoginErrors.customerInactive:
            case LoginErrors.customerInactiveDE:
            case LoginErrors.customerInactiveNL:
                errorMessage = (
                    <Trans id="user.loginPage.customerInactiveError">
                        Your account is not active. Please check your email to
                        activate your account.
                    </Trans>
                )
                break
            default:
                errorMessage = error
                break
        }
    }

    return errorMessage ? (
        <FormError autoHide={autoHide}>{errorMessage}</FormError>
    ) : null
}

const AccountAccessForm = ({ type = 'login' }: { type?: FormType }) => {
    const activeStoreView = useActiveStoreView()
    const subscribe = useNewsletterSubscribe()
    const [emailEntered, setEmailEntered] = useState<string | undefined>(
        undefined,
    )

    const { data: isEmailAvailable, loading: checkingEmail } =
        useIsEmailAvailable(emailEntered ?? '', {
            skip: !emailEntered,
        })

    const {
        handleSubmit: loginOrCreate,
        isLoading,
        error,
    } = useLoginForm({
        redirect: paths.account,
    })

    const customerExists = Boolean(emailEntered && isEmailAvailable === false)
    const handleSubmit = async ({
        username,
        password,
        firstname,
        lastname,
        dob,
        newsletter,
    }: AccountLoginPageFormValues) => {
        setEmailEntered(username)

        const isInvalidLogin =
            !password || !username || isEmailAvailable === undefined

        const isInvalidCreateAccount =
            !customerExists && (!firstname || !lastname)

        if (isInvalidLogin || isInvalidCreateAccount) {
            window.scrollTo(0, 0)
            return
        }

        if (newsletter) {
            subscribe(username, 'website_create_account')
        }

        await loginOrCreate({
            username,
            password,
            customer: !customerExists
                ? {
                      firstname: firstname ?? '',
                      lastname: lastname ?? '',
                      dob,
                  }
                : undefined,
        })
    }

    const customerDoesntExists = Boolean(
        emailEntered && isEmailAvailable === true,
    )

    const termsLink = <TermsLink />
    const rewardsLink = <RewardsLink />
    const privacyPolicyLink = <PrivacyPolicyLink />

    return (
        <>
            <Heading variant="h2" element="h1" className={styles.heading}>
                {customerExists ? (
                    <Trans id="account.login.headingCustomerExists">
                        Log in with your account
                    </Trans>
                ) : customerDoesntExists || type === 'register' ? (
                    <Trans id="account.login.headingCustomerDoesntExist">
                        I'd like to create an account
                    </Trans>
                ) : (
                    <Trans id="account.login.headingDefault">
                        Login or create an account
                    </Trans>
                )}
            </Heading>

            {customerExists ? (
                <Intro>
                    <Trans id="account.login.introLogin">
                        Fill in your password to log in
                    </Trans>
                </Intro>
            ) : customerDoesntExists || type === 'register' ? (
                <Intro>
                    <Trans id="account.login.introCreateAccount">
                        Fill in your details quickly and easily
                    </Trans>
                </Intro>
            ) : (
                <Intro>
                    <Trans id="account.login.introDefault">
                        Enter your email address to log in or create a new
                        account
                    </Trans>
                </Intro>
            )}

            <Form onSubmit={handleSubmit} noValidate data-cy="loginform">
                <LoginError error={error} />
                <FeedbackMessage />

                <FormGroup>
                    <Label>
                        <Trans id="account.login.emailLabel">
                            Email address
                        </Trans>
                        <Email
                            autoFocus
                            field="username"
                            disabled={Boolean(emailEntered)}
                        />
                    </Label>
                </FormGroup>

                {customerExists && (
                    <FormGroup>
                        <Label>
                            <Trans id="account.login.passwordLabel">
                                Password
                            </Trans>
                            <Password
                                field="password"
                                validate={validateRequired(
                                    t({
                                        id: 'account.login.passwordRequiredMessage',
                                        message: `Please enter your password`,
                                    }),
                                )}
                                autoComplete="current-password"
                                validateStrength={false}
                                autoFocus
                            />
                        </Label>
                    </FormGroup>
                )}

                {customerDoesntExists && (
                    <>
                        <FormGroup>
                            <Label>
                                <Trans id="account.register.firstnameLabel">
                                    First name
                                </Trans>
                                <Input
                                    field="firstname"
                                    type="text"
                                    validate={validateRequired(
                                        t({
                                            id: 'formValidation.firstNameRequired',
                                            message: `First name is required`,
                                        }),
                                    )}
                                />
                            </Label>
                        </FormGroup>

                        <FormGroup>
                            <Label>
                                <Trans id="account.register.lastnameLabel">
                                    Last name
                                </Trans>
                                <Input
                                    field="lastname"
                                    type="text"
                                    validate={validateRequired(
                                        t({
                                            id: 'formValidation.lastNameRequired',
                                            message: `Last name is required`,
                                        }),
                                    )}
                                />
                            </Label>
                        </FormGroup>

                        <FormGroup>
                            <Label>
                                <Trans id="account.register.dateOfBirthLabel">
                                    Date of birth
                                </Trans>
                                <Optional />
                                <Date required={false} field="dob" />
                            </Label>
                            {(activeStoreView.code.startsWith('pme') ||
                                activeStoreView.code.startsWith('justbrands') ||
                                activeStoreView.code.startsWith(
                                    'castiron',
                                )) && (
                                <Text color="grey" variant="secondary">
                                    <Trans id="account.register.dateOfBirthNote">
                                        Receive a free gift for your birthday
                                    </Trans>
                                </Text>
                            )}
                        </FormGroup>

                        <FormGroup>
                            <Label>
                                <Trans id="account.register.passwordLabel">
                                    Password
                                </Trans>
                                <Password
                                    field="password"
                                    validate={validateRequired(
                                        t({
                                            id: 'account.login.passwordRequiredMessage',
                                            message: `Please enter your password`,
                                        }),
                                    )}
                                    validateStrength
                                    autoComplete="new-password"
                                />
                            </Label>
                        </FormGroup>

                        <SubscribeInput />

                        <FormGroup>
                            <StyledCheckBox
                                field="gdprChecked"
                                validate={validateRequired(
                                    t({
                                        id: 'account.login.gdprRequired',
                                        message: `Please accept the terms and conditions and privacy policy`,
                                    }),
                                )}
                            >
                                <Text>
                                    <Trans id="account.register.gdpr">
                                        Yes, I agree to the {termsLink},{' '}
                                        {rewardsLink} and {privacyPolicyLink}.
                                    </Trans>
                                </Text>
                            </StyledCheckBox>
                        </FormGroup>
                    </>
                )}

                <Submit
                    variant="primary"
                    wide
                    name="Log in"
                    category="user.loginForm.submit"
                    loading={isLoading || checkingEmail}
                    disabled={isLoading || checkingEmail}
                >
                    {!emailEntered ? (
                        <Trans id="account.login.continue">Continue</Trans>
                    ) : customerDoesntExists ? (
                        <Trans id="account.login.submitCreateAccount">
                            Create account
                        </Trans>
                    ) : (
                        <Trans id="account.login.submit">Log in</Trans>
                    )}
                </Submit>

                {emailEntered && (
                    <Button
                        variant="link"
                        onClick={() => {
                            setEmailEntered(undefined)
                            window.scrollTo(0, 0)
                        }}
                        name=""
                        category=""
                        className={styles.backButton}
                    >
                        <Icon
                            component={StyledBackIcon}
                            title={t({
                                id: 'core.productPageTopBar.backButtonLabel',
                                message: `Back`,
                            })}
                        />
                        <Trans id="account.login.back">Back</Trans>
                    </Button>
                )}
            </Form>

            {customerExists && (
                <Link
                    name="Forgot passsord?"
                    category="account.login"
                    to={paths.forgotPassword}
                    className={styles.forgotPasswordLink}
                >
                    <Trans id="account.login.toForgotPassword">
                        Forgot your password?
                    </Trans>
                </Link>
            )}
        </>
    )
}

export default AccountAccessForm
