import { ApolloClient, gql, QueryHookOptions, useQuery } from '@apollo/client'

import {
    IsEmailAvailableOutput,
    Maybe,
    QueryIsEmailAvailableArgs,
} from '../../graphql/schema.generated'
import { validateEmail } from '../../input/Email/Email'

const query = gql`
    query isEmailAvailable($email: String!) {
        isEmailAvailable(email: $email) {
            isEmailAvailable
        }
    }
`

export interface IsEmailAvailableData {
    isEmailAvailable: Maybe<IsEmailAvailableOutput>
}

export const isEmailAvailable =
    (client: ApolloClient<unknown>) =>
    async (email: string): Promise<boolean> => {
        const result = await client.query<
            IsEmailAvailableData,
            Omit<QueryIsEmailAvailableArgs, 'store'>
        >({
            query,
            variables: {
                email,
            },
        })

        return result?.data?.isEmailAvailable?.isEmailAvailable ?? false
    }
export const useIsEmailAvailable = (
    email: string,
    options?: QueryHookOptions<
        IsEmailAvailableData,
        Omit<QueryIsEmailAvailableArgs, 'store'>
    >,
) => {
    const { data, error, loading, refetch } = useQuery<
        IsEmailAvailableData,
        Omit<QueryIsEmailAvailableArgs, 'store'>
    >(query, {
        ...options,
        variables: {
            email,
        },
        // Only execute when email is valid, otherwise graphql throws an error
        skip: options?.skip || validateEmail(email) !== undefined,
        errorPolicy: 'all',
    })

    if (error) {
        throw error
    }

    return {
        data: data?.isEmailAvailable?.isEmailAvailable,
        loading,
        refetch,
    }
}
