import { Trans } from '@lingui/macro'
import { JbfoLoginPage } from 'components/src/graphql/schema.generated'
import { getPrismicComponents } from 'components/src/htmlSerializer'
import Button from 'components/src/input/Button'
import Col from 'components/src/layout/Col'
import Container from 'components/src/layout/Container'
import Row from 'components/src/layout/Row'
import paths from 'components/src/paths'
import HeadingColor from 'components/src/types/HeadingColor'
import Heading from 'components/src/typography/Heading'
import Text from 'components/src/typography/Text'

import { PrismicRichText, RichTextBlock } from '@emico/prismic'
import { useBreakpoints } from '@emico/ui'

import styles from './AccountLoginPageLayout.module.scss'
import Form from './Form'

interface Props {
    prismicPage: JbfoLoginPage
}

export const LoginHeader = ({
    title,
    subTitle,
    color,
}: Pick<JbfoLoginPage, 'title' | 'subTitle'> & {
    color?: keyof typeof HeadingColor
}) => (
    <div className={styles.topHeading}>
        <div className={styles.headingMain}>
            <Heading variant="h2" element="h1" color={color ?? 'dark'}>
                {title}
            </Heading>
        </div>
        {subTitle && <Text className={styles.subHeading}>{subTitle}</Text>}
    </div>
)

const LoginContent = ({ prismicPage }: Props) => {
    const { isMobile } = useBreakpoints()

    return (
        <>
            {!isMobile && prismicPage && prismicPage.title && (
                <LoginHeader
                    subTitle={prismicPage.subTitle ?? ''}
                    title={prismicPage.title ?? ''}
                />
            )}

            <Row noGutters>
                <Col md={6} className={styles.contentWrapper}>
                    <Container>
                        {prismicPage && prismicPage.subHeading && (
                            <Heading
                                variant="h3"
                                element="h2"
                                color="dark"
                                className={styles.formHeading}
                            >
                                {prismicPage.subHeading}
                            </Heading>
                        )}
                        {prismicPage && prismicPage.content && (
                            <PrismicRichText
                                render={prismicPage.content as [RichTextBlock]}
                                components={getPrismicComponents({})}
                                analyticsContext="accountloginpagelayout.loginpage.content"
                            />
                        )}

                        <Button
                            minWidth
                            to={paths.register}
                            variant="primary"
                            wide
                            name="Create an account"
                            category="categoryElement.button"
                        >
                            <Trans id="account.login.createAccountButtonLabel">
                                Create an account
                            </Trans>
                        </Button>
                    </Container>
                </Col>
                <Col className={styles.formContainer} md={6}>
                    <Form />
                </Col>
            </Row>
        </>
    )
}

export default LoginContent
