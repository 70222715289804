import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { t, Trans } from '@lingui/macro'
import cx from 'classnames'
import { useFieldState } from 'informed'
import * as React from 'react'

import styles from './Password.module.scss'
import Icon from '../../media/Icon'
import EyeIconSlash from '../../static/icons/eye-slash.svg?react'
import EyeIcon from '../../static/icons/eye.svg?react'
import Button from '../Button'
import Input, { Props as InputProps } from '../Input'

const MIN_LENGTH = 7
const MIN_UPPERCASE = 1
const MIN_LOWERCASE = 1
const MIN_NUMBER = 1

type Props = Omit<InputProps<string>, 'type' | 'value'> & {
    optional?: boolean
    validateStrength: boolean
}

const validatePassword = (value: string) => {
    const minLength = value.length >= MIN_LENGTH
    const minUppercase = value.replace(/[^A-Z]/g, '').length >= MIN_UPPERCASE
    const minLowercase = value.replace(/[^a-z]/g, '').length >= MIN_LOWERCASE
    const minNumber = value.replace(/[^0-9]/g, '').length >= MIN_NUMBER

    const isValid = minLength && minUppercase && minLowercase && minNumber

    return {
        isValid,
        minLength,
        minUppercase,
        minLowercase,
        minNumber,
    }
}

const BasePassword = ({
    type = 'password',
    ...props
}: Omit<InputProps<string>, 'type'> & { type?: 'text' | 'password' }) => (
    <Input<string> type={type} {...props} />
)

const PasswordStrength = ({ field }: { field: string }) => {
    const { value } = useFieldState<string | undefined>(field)

    const { minLength, minUppercase, minLowercase, minNumber } =
        validatePassword(value || '')

    return (
        <div className={styles.requirements}>
            {MIN_LENGTH > 0 && (
                <div
                    className={cx(styles.requirement, {
                        [styles.valid]: minLength,
                    })}
                >
                    <Trans id="core.input.password.minCharacters">
                        {MIN_LENGTH} Characters
                    </Trans>
                </div>
            )}
            {MIN_UPPERCASE > 0 && (
                <div
                    className={cx(styles.requirement, {
                        [styles.valid]: minUppercase,
                    })}
                >
                    <Trans id="core.input.password.minUppercase">
                        {MIN_UPPERCASE} Uppercase
                    </Trans>
                </div>
            )}
            {MIN_LOWERCASE > 0 && (
                <div
                    className={cx(styles.requirement, {
                        [styles.valid]: minLowercase,
                    })}
                >
                    <Trans id="core.input.password.minLowercase">
                        {MIN_LOWERCASE} Lowercase
                    </Trans>
                </div>
            )}
            {MIN_NUMBER > 0 && (
                <div
                    className={cx(styles.requirement, {
                        [styles.valid]: minNumber,
                    })}
                >
                    <Trans id="core.input.password.minNumber">
                        {MIN_NUMBER} Number
                    </Trans>
                </div>
            )}
        </div>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
`

const EyeButton = styled(Button)`
    flex: 0 0 2em;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 2em;
    font-size: 1.5em;
    opacity: 0.5;
    outline: none !important;

    &:hover,
    &:focus {
        opacity: 1;
    }
`

const StyledIcon = styled(Icon)`
    fill: currentColor;
`

/**
 * Password input with strength indication.
 */
const Password = ({
    validate,
    optional,
    validateStrength,
    ...others
}: Props) => {
    const [isVisible, setVisible] = React.useState<boolean>(false)

    return (
        <>
            {validateStrength && <PasswordStrength field={others.field} />}
            <Wrapper>
                <BasePassword
                    {...others}
                    type={isVisible ? 'text' : 'password'}
                    validate={
                        !validateStrength
                            ? validate
                            : (
                                  value: string,
                                  ...args: unknown[]
                              ): string | undefined => {
                                  const error =
                                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                      // @ts-ignore
                                      validate && validate(value, ...args)

                                  if (error) {
                                      return error
                                  }
                                  if (
                                      optional &&
                                      (value === undefined || value === '')
                                  ) {
                                      return
                                  }

                                  if (
                                      !validatePassword(
                                          value === undefined ? '' : value,
                                      ).isValid
                                  ) {
                                      return t({
                                          id: 'core.passwordInput.invalid',
                                          message: `The password must meet all criteria.`,
                                      })
                                  }
                                  return
                              }
                    }
                />
                <EyeButton
                    name=""
                    category=""
                    onClick={() => setVisible(!isVisible)}
                    variant="link"
                >
                    <StyledIcon
                        component={isVisible ? EyeIconSlash : EyeIcon}
                        title="Toggle password visibility"
                    />
                </EyeButton>
            </Wrapper>
        </>
    )
}

export default Password
