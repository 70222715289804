import { i18n } from '@lingui/core'
import { t, Trans } from '@lingui/macro'
import CartItemConfiguration from 'components/src/cart/CartItemConfiguration'
import { Props } from 'components/src/cart/CartItemInfoContainer/CartItemInfoContainer'
import { LazyProductPage } from 'components/src/chunks'
import { WISHLIST_DISABLED } from 'components/src/constants'
import BinIcon from 'components/src/core/BinIcon'
import { Responsive } from 'components/src/core/Responsive'
import Button from 'components/src/input/Button'
import Icon from 'components/src/media/Icon'
import Link from 'components/src/navigation/Link'
import Text from 'components/src/typography/Text'
import { BrandMapper } from 'components/src/utils/BrandMapper'
import WishlistButton from 'components/src/wishlist/WishlistButton'

import styles from './CartItemInfoContainer.module.scss'

const CartItemInfoContainer = ({
    cartItem,
    totals,
    handleRemoveItem,
    handleMoveToWishlist,
    disabled,
}: Props) => {
    const handleMouseEnter = () => {
        LazyProductPage.preload()
    }
    const isFreeGift = cartItem.extensionAttributes.isFreeGift
    const priceLabel = isFreeGift ? (
        <Trans id="cart.cartItem.freeGiftLabel">Free gift</Trans>
    ) : (
        totals
    )

    const { product } = cartItem

    if (!product) {
        return null
    }

    return (
        <div className={styles.infoContainer}>
            <div className={styles.header}>
                <div className={styles.info}>
                    <Text as="h1" color="dark">
                        <Link
                            to={`/${product.urlKey}`}
                            name={product.name}
                            category="cart.cartPage.cartItem"
                            onMouseEnter={handleMouseEnter}
                            variant="dark"
                        >
                            {product.name}
                        </Link>
                    </Text>
                    <div className={styles.productNumber}>{product.sku}</div>
                    <div className={styles.productBrand}>
                        {BrandMapper(product.brand)?.prettyName}
                    </div>
                </div>
                <div className={styles.brandContainer}>
                    <img
                        src={BrandMapper(product.brand)?.logo}
                        alt={BrandMapper(product.brand)?.prettyName}
                        className={styles.brand}
                    />
                </div>
                <Responsive sm down>
                    <Button
                        variant="link"
                        onClick={handleRemoveItem}
                        name="Remove item from cart"
                        category="cart.removeFromCartButton"
                    >
                        <Icon
                            component={BinIcon}
                            title={t({
                                id: 'cart.cartItem.removeIconLabel',
                                message: `Remove item from cart`,
                            })}
                            className={styles.removeIcon}
                        />
                    </Button>
                </Responsive>
            </div>
            <div className={styles.configAndPrice}>
                <div className={styles.configuration}>
                    <CartItemConfiguration
                        cartItem={cartItem}
                        product={product}
                        disabled={Boolean(isFreeGift)}
                    />
                </div>
                <Responsive md up>
                    <div className={styles.priceContainer}>{priceLabel}</div>
                </Responsive>
            </div>
            <div className={styles.actions}>
                <Responsive md up>
                    {isFreeGift && (
                        <>
                            <Button
                                variant="link"
                                category="cart.cartGiftItem.edit"
                                name="Edit gift"
                                className={styles.removeButton}
                                onClick={() => {
                                    window.scrollTo({
                                        behavior: 'smooth',
                                        top: 0,
                                    })
                                }}
                            >
                                <Trans id="cart.cartItem.editGift">
                                    edit item
                                </Trans>
                            </Button>
                            | &nbsp;
                        </>
                    )}
                    <Button
                        variant="link"
                        onClick={handleRemoveItem}
                        name="Remove item from cart"
                        category="cart.removeFromCartButton"
                        data-testid="cart.item.remove"
                        className={styles.removeButton}
                    >
                        <Trans id="cart.cartItem.removeButtonLabelDesktop">
                            remove item from cart
                        </Trans>
                    </Button>
                    {!WISHLIST_DISABLED && !isFreeGift && (
                        <>
                            |
                            <div className={styles.bookmark}>
                                <WishlistButton
                                    className={styles.wishlistButton}
                                    product={product}
                                    optimistic={false}
                                    onAddToWishlist={handleMoveToWishlist}
                                    render={(isActive) => (
                                        <>
                                            {isActive ? (
                                                <Trans id="cart.cartItem.removeFromWishlistButtonLabel">
                                                    remove from wishlist
                                                </Trans>
                                            ) : (
                                                <Trans id="cart.cartItem.moveToWishlistButtonLabel">
                                                    move to wishlist
                                                </Trans>
                                            )}
                                        </>
                                    )}
                                />
                            </div>
                        </>
                    )}
                </Responsive>
                <Responsive sm down>
                    {priceLabel}
                </Responsive>
            </div>
        </div>
    )
}

export default CartItemInfoContainer
