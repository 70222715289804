import { i18n } from '@lingui/core'
import { t, Trans } from '@lingui/macro'

import Link from './navigation/Link'

const RewardsLink = () => (
    <Link
        name="rewards"
        category="account.register"
        to={t({
            id: 'core.rewardsLink',
            message: `/rewards`,
        })}
        target="_blank"
        variant="inverted"
    >
        <Trans id="core.rewardsLink.content">Just Rewards</Trans>
    </Link>
)

export default RewardsLink
