import { Trans } from '@lingui/macro'

import { useActiveStoreView } from '@emico/storeviews'

import Checkbox from './input/CheckBox/Informed'
import FormGroup from './input/FormGroup'
import Text from './typography/Text'

const SubscribeInput = ({ checkout = false }: { checkout?: boolean }) => {
    const activeStoreView = useActiveStoreView()

    return !activeStoreView.code.startsWith('outlet') &&
        !activeStoreView.code.startsWith('jbfo') &&
        !activeStoreView.code.startsWith('monster') ? (
        <FormGroup>
            <Checkbox field="newsletter">
                <Text>
                    {checkout ? (
                        <Trans id="checkout.register.newsletterSubscribe">
                            Yes, I’d like to receive the newsletter and not miss
                            any promotions or updates by email (optional)
                        </Trans>
                    ) : (
                        <Trans id="account.register.newsletterSubscribe">
                            Yes, I'd like to receive a coupon code for a €10
                            discount and not miss any promotions or updates by
                            email. (optional)
                        </Trans>
                    )}
                </Text>
            </Checkbox>
        </FormGroup>
    ) : null
}

export default SubscribeInput
