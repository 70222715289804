import * as React from 'react'

import styles from './FormGroup.module.scss'

interface OwnProps {
    children: React.ReactNode
}

type Props = OwnProps & React.HTMLAttributes<HTMLDivElement>

const FormGroup = (props: Props) => (
    <div {...props} className={styles.formGroup} />
)

export default FormGroup
