import { defineMessage } from '@lingui/macro'
import makeUrl from 'components/src/makeUrl'

import { getActiveStoreView, setStoreViews, StoreView } from '@emico/storeviews'

const storeViews: Array<
    StoreView & {
        prismicLocale?: string
    }
> = [
    {
        code: 'jbfo_nl',
        language: 'nl',
        locale: 'nl-nl',
        name: defineMessage({
            id: 'core.storeConfig.jbfo_nl',
            message: `Netherlands`,
        }),
        location: undefined,
        makeUrl: makeUrl(),
        basename: '',
        default: true,
        storeId: 19,
        websiteId: 8,
        prismic: 'jbfo',
        prismicLocale: 'nl-nl',
    },
]

const activeStoreView = getActiveStoreView(storeViews, window.location)
setStoreViews(storeViews, activeStoreView)

export default storeViews
