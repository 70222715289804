import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import * as React from 'react'

import Input, { Props as InputProps } from '../Input'

const pattern = /^(19|20)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|1\d|2\d|3[01])$/

type Props = Omit<InputProps<string>, 'type' | 'validate' | 'placeholder'> & {
    required?: boolean
}

const validateDate =
    (required: boolean) =>
    (value: string): string | undefined => {
        if ((required && !value) || (value && !pattern.test(value))) {
            return t({
                id: 'core.dateInput.invalidError',
                message: `Enter a valid date`,
            })
        }
        return undefined
    }

const Date = ({ required = true, ...props }: Props) => (
    <Input<string>
        {...props}
        type="date"
        placeholder="yyyy-mm-dd"
        validate={validateDate(required)}
    />
)

export default Date
