import { useApolloClient, gql, TypedDocumentNode } from '@apollo/client'
import { customerFragment } from '@emico-hooks/customer-fragment'
import { CustomerInput } from '@emico-hooks/graphql-schema-types'
import { useAuthorizationContext } from '@emico-hooks/login-token'
import { useCallback } from 'react'

import {
    UpdateCustomerMutation,
    UpdateCustomerMutationVariables,
} from './useUpdateCustomer.generated'

const updateCustomerMutation = gql`
    mutation UpdateCustomer($customer: CustomerInput!) {
        updateCustomer(input: $customer) {
            customer {
                ...CustomerFragment
            }
        }
    }

    ${customerFragment}
` as TypedDocumentNode<UpdateCustomerMutation, UpdateCustomerMutationVariables>

/**
 * Mutation to update customer data
 *
 * Usage example:
 *
 * ```ts
 * export function exampleFunction() {
 *  const updateCustomer = useUpdateCustomer()
 *
 *  return useCallback(
 *    async () => {
 *      try {
 *        await updateCustomer(customer)
 *      } catch(error) {
 *        // error
 *      }
 *    },
 *    [updateCustomer],
 *  )
 * }
 * ```
 */

export function useUpdateCustomer() {
    const client = useApolloClient()
    const authorizationContext = useAuthorizationContext()

    return useCallback(
        /**
         *
         * @param customer Customer data that contains changes to update the customer details
         */
        (customer: CustomerInput) =>
            client.mutate({
                mutation: updateCustomerMutation,
                variables: {
                    customer,
                },
                context: authorizationContext,
            }),
        [authorizationContext, client],
    )
}
