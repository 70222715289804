import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import {
    CartTotalsFooterPrice as CartTotalsFooterPriceDefault,
    priceStyling,
    Props,
} from 'components/src/cart/CartTotals/CartTotalsFooterPrice'
import Price from 'components/src/presentation/Price'
import theme from 'components/src/theme'
import { useSelector } from 'components/src/types'

const SavingRow = styled.tr`
    color: ${theme.colors.sale};
    font-weight: normal;
`

const PriceStyled = styled(Price)`
    color: ${theme.colors.sale};
    ${priceStyling}
`

const CartTotalsFooterPrice = (props: Props) => {
    const totals = useSelector((state) => state.cart.totals)
    return (
        <>
            <SavingRow>
                <td>
                    <Trans id="cart.cartTotals.savingLabel">You save</Trans>
                </td>
                <td>
                    {totals?.extension_attributes?.savings_incl_tax && (
                        <PriceStyled
                            price={totals.extension_attributes.savings_incl_tax}
                        />
                    )}
                </td>
            </SavingRow>
            <CartTotalsFooterPriceDefault {...props} />
        </>
    )
}

export default CartTotalsFooterPrice
