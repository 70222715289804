import 'components/src/initialize'

// Setup storeViews before loading the app
// eslint-disable-next-line import/order
import storeViews from './storeViews'
import { Messages } from '@lingui/core'
import launchStore from 'components'
import App from 'components/src/App'
import { overrideTheme } from 'components/src/appTheme'

import componentsConfig from './componentsConfig'
import fontsToPreload from './theme/fonts/fonts'

import './theme/fonts/fonts.scss'

const loadCatalog = (locale: string) => {
    const modules = import.meta.glob('./locales/*/messages.mjs')

    const module = modules[`./locales/${locale}/messages.mjs`]

    if (module) {
        return module() as Promise<Messages>
    }

    throw new Error('Could not load locale')
}

overrideTheme({
    colorSale: '#dc143c',
})

launchStore({
    storeViews,
    loadCatalog,
    preloadFonts: fontsToPreload,
    componentsConfig,
    App,
})
