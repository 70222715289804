import { usePrismicDocument } from '@emico/prismic'
import { useActiveStoreView } from '@emico/storeviews'

export interface PrismicLoginPage<T> {
    data: T
    loading: boolean
}

const usePrismicSinglePageLogin = <T>(urlKey: string): PrismicLoginPage<T> => {
    // Determine page template based on the current store view
    // The value of ID in `my.<ID>.<URL IDENTIFIER>` has to match with
    // a page type in prismic.
    // The value of URL IDENTIFIER has to match with a field, defined on the page type.
    const storeview = useActiveStoreView()
    const identifier = 'url_key'
    let pageTemplate = ''

    switch (storeview.prismic) {
        case 'pme_outlet':
            pageTemplate = 'pme_outlet_login_page'
            break
        case 'jbfo':
            pageTemplate = 'jbfo_login_page'
            break
        case 'monstersale':
            pageTemplate = 'monstersale_login_page'
            break
        default:
            pageTemplate = ''
    }
    return usePrismicDocument<T>(
        {
            key: `my.${pageTemplate}.${identifier}`,
            value: urlKey,
        },
        false,
    )
}

export default usePrismicSinglePageLogin
