import { useApolloClient, gql, TypedDocumentNode } from '@apollo/client'
import { useAuthorizationContext } from '@emico-hooks/login-token'
import { useCallback } from 'react'

import {
    ResetPasswordMutation,
    ResetPasswordMutationVariables,
} from './useResetPassword.generated'

const resetPasswordMutation = gql`
    mutation ResetPassword(
        $email: String!
        $resetPasswordToken: String!
        $newPassword: String!
    ) {
        resetPassword(
            email: $email
            resetPasswordToken: $resetPasswordToken
            newPassword: $newPassword
        )
    }
` as TypedDocumentNode<ResetPasswordMutation, ResetPasswordMutationVariables>

/**
 * Mutation to reset the password of a customer account
 *
 * Usage example:
 *
 * ```ts
 * export function exampleFunction() {
 *  const resetPassword = useResetPassword()
 *
 *  return useCallback(
 *    async () => {
 *      try {
 *        await resetPassword(email, resetPasswordToken, newPassword)
 *      } catch(error) {
 *        // error
 *      }
 *    },
 *    [resetPasswordMutation],
 *  )
 * }
 * ```
 */

export function useResetPassword() {
    const client = useApolloClient()
    const authorizationContext = useAuthorizationContext()

    return useCallback(
        /**
         * @param email The customer's email address
         * @param resetPasswordToken A runtime token generated by the requestPasswordResetEmail mutation
         * @param newPassword The customer's new password
         */
        (email: string, resetPasswordToken: string, newPassword: string) =>
            client.mutate({
                mutation: resetPasswordMutation,
                variables: {
                    email,
                    resetPasswordToken,
                    newPassword,
                },
                context: authorizationContext,
            }),
        [authorizationContext, client],
    )
}
