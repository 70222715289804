import { useApolloClient, gql, TypedDocumentNode } from '@apollo/client'
import { useAuthorizationContext } from '@emico-hooks/login-token'
import { useCallback } from 'react'

import {
    ChangeCustomerPasswordMutation,
    ChangeCustomerPasswordMutationVariables,
} from './useChangeCustomerPassword.generated'

const changeCustomerPasswordMutation = gql`
    mutation ChangeCustomerPassword(
        $currentPassword: String!
        $newPassword: String!
    ) {
        changeCustomerPassword(
            currentPassword: $currentPassword
            newPassword: $newPassword
        ) {
            email
        }
    }
` as TypedDocumentNode<
    ChangeCustomerPasswordMutation,
    ChangeCustomerPasswordMutationVariables
>

/**
 * Mutation to change the password of a customer account
 *
 * Usage example:
 *
 * ```ts
 * export function exampleFunction() {
 *  const changeCustomerPassword = useChangeCustomerPassword()
 *
 *  return useCallback(
 *    async () => {
 *      try {
 *        await changeCustomerPassword(currentPassword, newPassword)
 *      } catch(error) {
 *        // error
 *      }
 *    },
 *    [changeCustomerPassword],
 *  )
 * }
 * ```
 */

export function useChangeCustomerPassword() {
    const client = useApolloClient()
    const authorizationContext = useAuthorizationContext()

    return useCallback(
        /**
         *
         * @param currentPassword The current password of the customer
         * @param newPassword The new password of the customer
         */
        (currentPassword: string, newPassword: string) =>
            client.mutate({
                mutation: changeCustomerPasswordMutation,
                variables: {
                    currentPassword,
                    newPassword,
                },
                context: authorizationContext,
            }),
        [authorizationContext, client],
    )
}
