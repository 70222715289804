import cx from 'classnames'
import DefaultCartItemImage from 'components/src/cart/CartItemImage'
import { LazyProductPage } from 'components/src/chunks'
import ImageLazy from 'components/src/media/ImageLazy'
import Link from 'components/src/navigation/Link'
import { ComponentProps } from 'react'

import { ImaginaryActions } from '@emico/ui'

import styles from './CartItemImage.module.scss'

const CartItemImage = ({
    product,
    variant = 'standard',
    disabled,
}: ComponentProps<typeof DefaultCartItemImage>) => {
    const modelImage = product.modelImage === '1' ? true : false

    const handleMouseEnter = () => {
        LazyProductPage.preload()
    }

    return (
        <figure
            className={cx(styles.imageContainer, {
                [styles.gift]: variant === 'gift',
                [styles.disabled]: disabled,
            })}
        >
            <Link
                to={`/${product.urlKey}`}
                name={product.name}
                category="cart.cartPage.cartItem"
                onMouseEnter={handleMouseEnter}
            >
                <ImageLazy
                    alt=""
                    images={{
                        image: product.smallImage?.url ?? '',
                        regularWidth: 210,
                        mediumWidth: 100,
                        smallWidth: 100,
                        action: ImaginaryActions.RESIZE,
                    }}
                    className={cx(styles.image, {
                        [styles.modelImage]: modelImage,
                    })}
                />
            </Link>
        </figure>
    )
}

export default CartItemImage
