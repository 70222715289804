import { TypedDocumentNode, useQuery, gql } from '@apollo/client'
import { QueryHookOptions } from '@apollo/client/react/types/types'
import { customerFragment } from '@emico-hooks/customer-fragment'
import { useAuthorizationContext } from '@emico-hooks/login-token'

import { CustomerQuery, CustomerQueryVariables } from './useCustomer.generated'

const customerQuery = gql`
    query Customer {
        customer {
            ...CustomerFragment
        }
    }

    ${customerFragment}
` as TypedDocumentNode<CustomerQuery, CustomerQueryVariables>

export function useCustomer(
    options?: QueryHookOptions<CustomerQuery, CustomerQueryVariables>,
) {
    const authorizationContext = useAuthorizationContext()

    const { data, ...others } = useQuery(customerQuery, {
        context: authorizationContext,
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
        ...options,
    })

    return {
        ...others,
        data: data?.customer ?? undefined,
    }
}
