import { useQuery, gql, TypedDocumentNode } from '@apollo/client'
import { getCacheableContext } from '@emico-utils/graphql-data-utils'

import {
    CountriesQuery,
    CountriesQueryVariables,
} from './useCountries.generated'

const countriesQuery = gql`
    query Countries {
        countries {
            id
            fullNameLocale
            twoLetterAbbreviation

            availableRegions {
                code
                id
                name
            }
        }
    }
` as TypedDocumentNode<CountriesQuery, CountriesQueryVariables>

export function useCountries() {
    const { data, ...others } = useQuery(countriesQuery, {
        context: getCacheableContext(),
        errorPolicy: 'all',
    })

    return {
        ...others,
        data: data?.countries,
    }
}
